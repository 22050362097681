// import React, { Component } from 'react';
// export default class ContactUs extends Component {
//   render() {
//     let resumeData = this.props.resumeData;
//     return (
//       <section id="contact">
//         <div className="row section-head">
//           <div className="ten columns">
//             <p className="lead">
//               Feel free to contact me for any work or suggestions below
//               </p>
//           </div>
//         </div>
//         <div className="row">
//           <aside className="eigth columns footer-widgets">
//             <div className="widget">
//               <h4>Linked in :
//                   {resumeData.linkedinId}
//               </h4>
//             </div>
//           </aside>
//         </div>
//       </section>
//     );
//   }
// }

import React from 'react';
import emailjs, { init } from 'emailjs-com';
import styled from 'styled-components';

init("user_9hz0OeDfaAL1qLpg4a9xW")


export default function ContactUs() {

  const sendEmail = e => {
    // e.preventDefault();

    emailjs.sendForm('service_lmd7zdp', 'template_tgdt35o', e.target, 'user_9hz0OeDfaAL1qLpg4a9xW')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  return (
    <>
      <h2 id="contact">Contact Me</h2>
      <form className="contact-form" onSubmit={sendEmail}>
        <input type="hidden" name="contact_number" />
        <label>Name</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Message</label>
        <textarea name="message" />
        <input type="submit" value="Send" />
      </form>
    </>
  );
}