let resumeData = {
  "imagebaseurl": "https://github.com/ryanmccutcheon21",
  "name": "Ryan McCutcheon",
  "role": "Full-stack Developer",
  "linkedinId": " ryan-mccutcheon-185b261b8",
  "skypeid": "Your skypeid",
  // "roleDescription": " I stay up to date with current technology trends, and implement the latest and greatest into my websites. I love working with either Vue or React, and use Node.js for backends.",
  "socialLinks": [
    {
      "name": "linkedin",
      "url": "https://www.linkedin.com/in/ryan-mccutcheon-185b261b8/",
      "className": "fa fa-linkedin"
    },
    {
      "name": "github",
      "url": "https://github.com/ryanmccutcheon21",
      "className": "fa fa-github"
    },
    {
      "name": "twitter",
      "url": "https://twitter.com/home",
      "className": "fa fa-twitter"
    }
  ],
  "aboutme": `I am a web developer capable of working with the 
  full stack of development. With expert knowledge of HTML, CSS, and JavaScript, 
  I utilize many differnet frameworks and libraries on the front-end. My favorites
  being either Vue or React. On the back-end, I use Node.js to handle 
  interactions with the server and databases. I stay up to date with current
  technology trends, and implement the latest and greatest into my websites. I am
  available for contract/freelance work, web developer positions, and mentorship. Don't
  hesitate to reach out to me by filling out the contact form below!`,
  // "address": "158 Pecan Ln Greenwood, AR 72936",
  // "website": "ryanmccutcheon21@gmail.com",
  "education": [
    {
      "UniversityName": "University of Arkansas - Fort Smith",
      "specialization": "Criminal Justice",
      "MonthOfPassing": "May",
      "YearOfPassing": "2019",
      // "Achievements": "Some Achievements"
    },
    {
      "UniversityName": "The Johns Hopkins University",
      "specialization": "HTML, CSS, and JavaScript for Web Developers",
      "MonthOfPassing": "Dec",
      "YearOfPassing": "2020",
      // "Achievements": "Some Achievements"
    }
  ],
  "work": [
    {
      "CompanyName": "Web Developer",
      "specialization": "Freelance",
      "MonthOfLeaving": "Oct",
      "YearOfLeaving": "2020",
      "Achievements": "Current"
    },
    {
      "CompanyName": "Fort Smith Fire Department",
      "specialization": "Firefighter",
      "MonthOfLeaving": "Feb",
      "YearOfLeaving": "2020",
      "Achievements": "Current"
    }
  ],
  // "skillsDescription": "My ninja skills",
  "skills": [
    {
      "skillname": "HTML"
    },
    {
      "skillname": "CSS"
    },
    {
      "skillname": "JavaScript"
    },
    {
      "skillname": "Bootstrap"
    },
    {
      "skillname": "React & Redux"
    },
    {
      "skillname": "Vue"
    },
    {
      "skillname": "ExpressJS"
    },
    {
      "skillname": "Mongoose"
    },
    {
      "skillname": "EJS"
    },
    {
      "skillname": "RESTful API"
    },
  ],
  "skills2": [
    {
      "skillname": "MongoDB"
    },
    {
      "skillname": "Firebase Realtime Database"
    },
    {
      "skillname": "Cloudinary"
    },
    {
      "skillname": "Mapbox"
    },
    {
      "skillname": "PassportJS"
    },
    {
      "skillname": "Wordpress"
    },
    {
      "skillname": "Elementor"
    },
    {
      "skillname": "EmailJS"
    },
    {
      "skillname": "D3"
    },
    {
      "skillname": "Stripe Payments"
    },
  ],

  "portfolio": [
    {
      "name": "T-town realty",
      "description": "Real Estate Website",
      "imgurl": "images/portfolio/t-town.png",
      "url": "https://www.t-townrealty.com",
      "key": "1"
    },
    {
      "name": "BucketList",
      "description": "Yelp style website",
      "imgurl": "images/portfolio/project2.png",
      "url": "https://bucketlist-adventures.herokuapp.com/",
      "key": "2"
    },
    {
      "name": "Tourism.com",
      "description": "Tourism landing site",
      "imgurl": "images/portfolio/project3.png",
      "url": "https://ryanmccutcheon21.github.io/LandingPage/",
      "key": "3"
    },
    {
      "name": "Slack2.0",
      "description": "Slack clone",
      "imgurl": "images/portfolio/slack-2.0.png",
      "url": "https://slack-clone-c571c.web.app/",
      "key": "4"
    },
    {
      "name": "TechFreak",
      "description": "eCommerce store",
      "imgurl": "images/portfolio/techfreak.png",
      "url": "https://techfreak-aa802.web.app/",
      "key": "5"
    },
  ],
  "testimonials": [
    {
      "description": "Ryan far exceeded my expectations. I had him build a website for my real estate business and it immediatly paid off. I saw instant results and even recieved compliments from clients about how professional my website was.  ",
      "name": "T-town Realty"
    },
    // {
    //   "description": "This is a sample testimonial",
    //   "name": "Some technical guy"
    // }
  ]
}

export default resumeData